import '../styles/blog.css'

import * as React from 'react'

import { navigate } from 'gatsby'
import { SEO } from '../components/Seo'

const ReadmeBump = () => {
  React.useEffect(() => {
    setTimeout(() => {
      navigate('/blog/2020-05-25-personal-readme', { replace: true })
    }, 100)
  }, [])
  return (
    <span>
      <SEO title="Max Cantor's README" description="Thoughts on principle driven management and how I like to work" />
    </span>
  )
}
export default ReadmeBump
